@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: rgb(243 244 246);
  display: flex;
  height: 100%;
}

body {
  display: flex;
  flex: 1 1 auto;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
}